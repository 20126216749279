import FontFaceObserver from 'fontfaceobserver'

export default () => {
	const $html = document.getElementsByTagName('html')[0]
	const italic = new FontFaceObserver('messina-italic')
	const regular = new FontFaceObserver('messina-regular')
	const bold = new FontFaceObserver('messina-bold')

	const sanslight = new FontFaceObserver('messinasans-light')
	const sansitalic = new FontFaceObserver('messinasans-regularitalic')
	const sansregular = new FontFaceObserver('messinasans-regular')
	const sansbold = new FontFaceObserver('messinasans-bold')

	Promise.all([
		italic.load(),
		regular.load(),
		bold.load(),
		sanslight.load(),
		sansitalic.load(),
		sansregular.load(),
		sansbold.load(),
	])
		.then(() => $html.classList.add('fonts-loaded'))
		.catch(() => $html.classList.add('fonts-failed'))
}
