const detectTab = () => {
	window.addEventListener('keydown', (event) => {
		if (event.keyCode === 9) {
			document.body.classList.add('user-is-tabbing')
			window.removeEventListener('keydown', null)
		}
	})
}

export default detectTab
