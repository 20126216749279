import '/src/scss/style.scss'

if (import.meta.hot) {
	import.meta.hot.accept()
}

import '@/plugins/logger'

import objectFitImages from 'object-fit-images'
import webfontloader from '@/plugins/webfontloader'
import 'lazysizes'

// Global Stuffs
import detectOS from '@/global/detect-os'
import detectTab from '@/global/detect-tab'

webfontloader()

async function init() {
	const [App] = await Promise.all([import('@/core/App'), import('@/views')])

	document.body.setAttribute('data-transition-state', 'on-load')
	/* eslint-disable-next-line */
	new App.default({
		// @property {Array} routes - routes object
		// @property {HTMLElement} rootNode - the root html node
		// @property {Array} navLinks - an array of links that should update on navigation
		// @property {Object} classes - clases applied to active links
		// @property {Function} onExit - called before the dom is updated
		// @property {Function} function - called after the dom is updated
		router: null,

		// @property {Function} routes - dynamic import of modules - function used by the loader
		chunks: (behaviour) => import(`./behaviours/${behaviour}.js`),
	}).mount()
}

init()
objectFitImages()
detectOS()
detectTab()
