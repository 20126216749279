import { detect } from 'detect-browser'

const detectOS = () => {
	const browser = detect()
	if (browser) {
		const niceOS = browser.os.replace(' ', '-').toLowerCase()
		document.body.classList.add(niceOS)
	}
}

export default detectOS
